import React from 'react';

function OnboardingStepHeader({title, description}) {

    return (
        <div className='flex flex-col gap-3'>
            <p className='text-2xl md:text-4xl font-semibold text-center'>
                {title}
            </p>
            <p className='text-md text-slate-500 text-center'>
                {description}
            </p>
        </div>
    );
}

export default OnboardingStepHeader;

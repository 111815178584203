import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import parsePhoneNumber from "libphonenumber-js";
import {DateTime} from "luxon";
import {getBlockedHosts} from "../api/blocked_hosts";
import TextInputWrapper from "../components/atoms/TextInputWrapper";
import SvgInstagram from "../components/icons/SvgInstagram";
import HorizontalDivider from "../components/atoms/HorizontalDivider";
import SvgPhone from "../components/icons/SvgPhone";
import SvgMail from "../components/icons/SvgMail";
import {Button} from "../components/atoms/Button";
import {toast} from "react-toastify";
import {TOAST_OPTIONS} from "../utils/consts";
import SvgHelp from "../components/icons/SvgHelp";
import SvgUserCard from "../components/icons/SvgUserCard";
import TooltipComponent from "../components/atoms/TooltipComponent";
import {editProfile} from '../api/profiles';
import SvgLogout from "../components/icons/SvgLogout";
import {signOut} from "../api/auth";
import {Link, useNavigate} from "react-router-dom";

const mapStateToProps = (state) => {
    return {
        authSession: state.userReducer.authSession,
        userProfile: state.userReducer.userProfile
    }
}

function ProfilePage(props) {
    const [blockedHosts, setBlockedHosts] = useState([])
    const [instagram, setInstagram] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [isEdited, setIsEdited] = useState(false);
    const [isSavingChanges, setIsSavingChanges] = useState(false)
    const [authenticated, setAuthenticated] = useState(false);
    const profile = props.userProfile
    const navigate = useNavigate();
    const currentTier = props.userProfile?.tier || "Free";

    useEffect(() => {
        getUserBlockedHosts()
        setInstagram(profile.instagram || '');
        setDisplayName(profile.display_name || '');
    }, [profile])

    const getUserBlockedHosts = async () => {
        const profileId = profile.id;
        const res = await getBlockedHosts(profileId);
        setBlockedHosts(res.map(item => `${item?.host?.first_name} ${item?.host?.last_name}`))
    }
    const handleInstagramChange = (event) => {
        setInstagram(event.target.value);
        checkIfEdited(event.target.value, displayName);
    }

    const handleDisplayNameChange = (event) => {
        setDisplayName(event.target.value);
        checkIfEdited(instagram, event.target.value);
    }

    const normalizeValue = (value) => {
        return value === '' ? null : value;
    }

    const checkIfEdited = (newInstagram, newDisplayName) => {
        const normalizedNewInstagram = normalizeValue(newInstagram);
        const normalizedNewDisplayName = normalizeValue(newDisplayName);
        const instagramChanged = normalizedNewInstagram !== (profile.instagram || null);
        const displayNameChanged = normalizedNewDisplayName !== (profile.display_name || null);
        setIsEdited(instagramChanged || displayNameChanged);
    }

    const handleSubmit = async () => {
        setIsSavingChanges(true)

        try {
            await editProfile(profile.id, {
                instagram,
                display_name: displayName
            })
            toast.success("Changes saved", TOAST_OPTIONS);
        } catch (err) {
            toast.error("Failed to save changes", TOAST_OPTIONS);
        } finally {
            setIsEdited(false);
            setIsSavingChanges(false)
        }
    };

    useEffect(() => {
        setAuthenticated(!!props.authSession)
    }, [props.authSession])

    const handleSignOut = () => {
        signOut();
        navigate('/auth')
    }

    return (
        <div className='w-full max-w-[1400px] mx-auto pt-14 flex flex-col items-center flex-1 pb-20'>
            <div className='w-full px-4 md:w-[640px] flex flex-col gap-5 md:mt-12'>
                <div className='flex flex-col gap-3 '>
                    <div className='mt-5 flex flex-row flex-wrap items-center justify-between'>
                        <p className='text-2xl font-semibold'>
                            My Profile
                        </p>
                        <div className='flex flex-row items-center justify-between'>
                            <div className='flex flex-row items-center justify-center gap-2'>
                                <p className='text-xs md:text-sm text-gray-500'>
                                    You're on the <span className={`${currentTier !== 'Free' && 'text-primary'} font-semibold`}>{currentTier} Plan</span>
                                </p>
                                <Link to={'/pricing'}>
                                    <Button variant={currentTier === 'Free' ? 'primary' :'secondary'} size={'sm'}>
                                        {currentTier === 'Free' ? 'Upgrade' : "View"}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <HorizontalDivider className='bg-slate-200 mb-5'/>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-gray-500 text-xs md:text-sm'>
                            Display Name:
                        </p>
                        <TooltipComponent
                            ToggleComponent={
                                <SvgHelp className={'ml-1 w-4 h-4 text-slate-500 hover:text-primary'}/>
                            }
                            hover
                            placement={'bottom'}
                            label={'Set your display name for events you organize or host. As a guest, your ticket is tied to your real name, which must to match your government ID. Any issues? Contact support, we\'re happy to help.'}
                        />
                        <div className='ml-auto'>
                            <TextInputWrapper
                                value={displayName}
                                onChange={handleDisplayNameChange}
                                icon={SvgUserCard}
                                placeholder={profile.first_name + ' ' + profile.last_name}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-gray-500 text-xs md:text-sm'>
                            Email:
                        </p>
                        <div className='ml-auto'>
                            <TextInputWrapper
                                value={profile.email}
                                icon={SvgMail}
                                placeholder={'Your email'}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-gray-500 text-xs md:text-sm'>
                            Phone:
                        </p>
                        <div className='ml-auto'>
                            <TextInputWrapper
                                value={parsePhoneNumber(profile.phone_number || "", 'US')?.formatNational() || "No phone"}
                                icon={SvgPhone}
                                placeholder={'Your phone'}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-gray-500 text-xs md:text-sm mr-auto'>
                            Instagram:
                        </p>
                        <div className='ml-auto'>
                            <TextInputWrapper
                                value={instagram}
                                icon={SvgInstagram}
                                placeholder={'Your username'}
                                onChange={handleInstagramChange}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <Button onClick={handleSubmit} variant={'default'} disabled={!isEdited || isSavingChanges}
                                loading={isSavingChanges} className='ml-auto'>
                            Save Changes
                        </Button>
                    </div>
                    <HorizontalDivider className='bg-slate-200 my-5'/>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-gray-500 text-xs md:text-sm'>
                            Name:
                        </p>
                        <p className='md:text-lg'>{profile.first_name} {profile.last_name}</p>
                    </div>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-gray-500 text-xs md:text-sm'>
                            Date of Birth:
                        </p>
                        <p className='md:text-lg'>{profile.date_of_birth}</p>
                    </div>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-gray-500 text-xs md:text-sm'>
                            Gender:
                        </p>
                        <p className='md:text-lg'>{profile.gender}</p>
                    </div>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-gray-500 text-xs md:text-sm'>
                            Created:
                        </p>
                        <p className='md:text-lg'>{`${DateTime.fromISO(profile.created_at, {zone: 'utc'}).toRelative() || "--"}`}</p>
                    </div>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-gray-500 text-xs md:text-sm'>
                            Blocked Hosts:
                        </p>
                        <p className='md:text-lg'>{!!blockedHosts.length ? blockedHosts.join(", ") : "None"}</p>
                    </div>
                </div>
                <div
                    className='flex px-4 py-2 flex-row gap-3 items-center rounded-xl bg-primary-50 text-primary'>
                    <SvgHelp className={'w-5 h-5'}/>
                    <p>
                        Need help? Contact us at <a href="mailto:support@opassity.com"
                                                    className='font-semibold'>support@opassity.com</a>.
                    </p>
                </div>
                <div className='flex flex-row items-center gap-3 justify-center'>
                    <Button onClick={handleSignOut} icon={SvgLogout} variant={'secondary'}>
                        Log Out
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(ProfilePage)

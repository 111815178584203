import React, {useState} from 'react';
import TextInputWrapper from "../atoms/TextInputWrapper";
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingButtons from "./OnboardingButtons";
import PhoneInputWrapper from "../atoms/PhoneInputWrapper";
import {checkProfileExists} from 'api/profiles';
import RsvpDetailsModal from '../modals/OnboardingDetailsModal';
import CheckboxItem from "../atoms/CheckboxItem";

export default function Page({
                                 goNextStep,
                                 firstName,
                                 setFirstName,
                                 lastName,
                                 setLastName,
                                 phone,
                                 setPhone,
                                 email,
                                 setEmail,
                                 acceptedTerms,
                                 setAcceptedTerms
                             }) {
    const [errorMessage, setErrorMessage] = useState('');
    const [modal1Open, setModal1Open] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);

    const handleFirstNameChange = (event) => {
        if (errorMessage) setErrorMessage('');
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        if (errorMessage) setErrorMessage('');
        setLastName(event.target.value);
    };

    const handlePhoneChange = (phone) => {
        if (errorMessage) setErrorMessage('');
        setPhone(phone);
    };

    const handleEmailChange = (event) => {
        if (errorMessage) setErrorMessage('');
        setEmail(event.target.value.toLowerCase());
    };

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const validatePhone = async (phone) => {
        if (phone.length >= 10 && (await checkProfileExists(phone))) {
            setErrorMessage('Phone number is already taken.');
            return false;
        }
        return /^\d{10,15}$/.test(phone.replace(/[\s()-]/g, ''));
    }

    const validateForm = async () => {
        if (!firstName) {
            setErrorMessage('Please enter your first name.');
            return false;
        }
        if (!lastName) {
            setErrorMessage('Please enter your last name.');
            return false;
        }
        if (!email || !validateEmail(email)) {
            setErrorMessage('Please enter a valid email address.');
            return false;
        }
        if (!phone || !(await validatePhone(phone))) {
            setErrorMessage(errorMessage => errorMessage || 'Please enter a valid phone number.');
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (await validateForm()) {
            goNextStep();
        }
    };

    return (
        <div className='flex flex-col gap-8 w-screen px-4 md:w-[460px]'>
            <OnboardingStepHeader
                title={'RSVP Details'}
                description={''}
            />
            <div className='flex flex-col gap-5 w-full items-center'>
                <div className='w-full'>
                    <div className='flex flex-col md:flex-row gap-3 w-full'>
                        <TextInputWrapper
                            label={
                                <span>
                            <p className="inline">First Name</p>
                            <p className="inline text-red-500 pl-1">*</p>
                          </span>
                            }
                            value={firstName}
                            placeholder={'John'}
                            onChange={handleFirstNameChange}
                            autoComplete="given-name"
                        />
                        <TextInputWrapper
                            label={
                                <span>
                            <p className="inline">Last Name</p>
                            <p className="inline text-red-500 pl-1">*</p>
                          </span>
                            }
                            value={lastName}
                            placeholder={'Doe'}
                            onChange={handleLastNameChange}
                            autoComplete="family-name"
                        />
                    </div>
                    <p className="text-xs mt-2 text-gray-500">Your name <span className='underline'>must</span> match
                        your gov't issued ID. <button
                            className="cursor-pointer font-semibold text-primary-400 hover:text-primary-600"
                            onClick={() => setModal1Open(true)}>Why?</button>
                    </p>
                </div>
                <div className='flex flex-col gap-3 w-full'>
                    <TextInputWrapper
                        id={'email-input'}
                        label={
                            <span>
                            <p className="inline">Email</p>
                            <p className="inline text-red-500 pl-1">*</p>
                          </span>
                        }
                        value={email}
                        type={'email'}
                        placeholder={'john.smith@gmail.com'}
                        onChange={handleEmailChange}
                    />
                </div>
                <div className='flex flex-col gap-3 w-full'>
                    <PhoneInputWrapper
                        label={
                            <span>
                            <p className="inline">Phone</p>
                            <p className="inline text-red-500 pl-1">*</p>
                          </span>
                        }
                        value={phone}
                        onChange={handlePhoneChange}
                    />
                </div>
                <p className={`text-xs h-4 ${errorMessage ? 'text-red-400' : 'text-transparent'}`}>
                    {errorMessage}
                </p>
                <div className='flex flex-row items-center gap-1'>
                    <CheckboxItem
                        name={'requires-approval'}
                        checked={acceptedTerms}
                        onChange={(e) => setAcceptedTerms(!acceptedTerms)}
                    />
                    <p className="text-xs text-gray-500">By submitting your RSVP, you consent to receiving email and
                        text
                        invitations and critical event updates only. You will not be spammed. <button
                            className="cursor-pointer font-semibold text-primary-400 hover:text-primary-600"
                            onClick={() => setModal2Open(true)}>Read more</button>.
                    </p>
                </div>
            </div>

            <OnboardingButtons
                goNextStep={handleSubmit}
                backHidden
                nextDisabled={!firstName || !lastName || !email || !phone || !validateEmail(email) || !validatePhone(phone) || !acceptedTerms}
            />
            <RsvpDetailsModal
                title={'Why this is important'}
                text={'Your data is only being used and stored for the purpose of this event RSVP, and to make future RSVPs quicker and easier. There is no app to download. Read the Data and Privacy Policy for more details.'}
                isOpen={modal1Open} close={() => setModal1Open(false)}/>
            <RsvpDetailsModal
                title={'More details'}
                text={'Organizers are prevented from sending non-critical information or contacting guests redundantly. You will not be spammed, solicited, or marketed to without your consent. You may opt-out at any time from receiving invites from organizers. Read the Terms of Service and Data and Privacy Policy for more details'}
                isOpen={modal2Open} close={() => setModal2Open(false)}/>
        </div>
    );
}

